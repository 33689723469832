/**
 * By default, Remix will handle hydrating your app on the client for you. You
 * are free to delete this file if you'd like to, but if you ever want it
 * revealed again, you can run `npx remix reveal` ✨ For more information, see
 * https://remix.run/file-conventions/entry.client
 */
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import {
  browserTracingIntegration,
  init as initSentry,
  replayIntegration,
} from "@sentry/remix";
import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

initSentry({
  dsn: "https://abe14e42ba67fa01ffc682c016f23faa@o4506597307908096.ingest.us.sentry.io/4506934226386944",
  enabled: process.env.NODE_ENV !== "development",
  integrations: [
    browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    replayIntegration(),
  ],
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 1,
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
